import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { useMediaQueries } from "@react-hook/media-query"
import {
  Container,
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Button,
} from "reactstrap"
import classnames from "classnames"
import AnchorLink from "react-anchor-link-smooth-scroll"

import classNames from "classnames"

const NavbarPremier = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenWeb, setIsOpenWeb] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const {
    matches: { isMobile },
  } = useMediaQueries({
    isMobile: "only screen and (max-width: 992px)",
  })

  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 80) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  const toggle = () => setIsOpen(!isOpen)

  const toggleWeb = () => setIsOpenWeb(!isOpenWeb)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  return (
    <header className={classnames({ scrolled: scrolled, "py-0": true })}>
      <Container>
        <Navbar
          dark
          color="dark"
          className="main-navbar px-0"
          sticky="top"
          expand="lg"
        >
          <NavbarBrand
            href="/"
            title="Home"
            className="mr-auto main-navbar--brand"
          />
          <NavbarToggler
            className={classnames({
              "main-navbar--toggler p-0 w-0": true,
              "main-navbar--toggler-active": isOpen,
            })}
            onClick={toggle}
          />

          <Collapse isOpen={isOpen} navbar className="justify-content-end">
            <Nav className="main-navbar--nav mt-4 mt-md-0" navbar>
              <NavItem>
                <AnchorLink
                  className="nav-link nav-link--item"
                  offset="80"
                  href="#home"
                >
                  Home
                </AnchorLink>
              </NavItem>
              {!isMobile && (
                <>
                  <NavItem>
                    <AnchorLink
                      className="nav-link nav-link--item"
                      offset="80"
                      href="#our-services"
                    >
                      Our Services
                    </AnchorLink>
                  </NavItem>
                  <NavItem>
                    <AnchorLink
                      className="nav-link nav-link--item"
                      offset="80"
                      href="#creations"
                    >
                      Creations
                    </AnchorLink>
                  </NavItem>
                  <NavItem>
                    <AnchorLink
                      className="nav-link nav-link--item"
                      offset="80"
                      href="#about"
                    >
                      About
                    </AnchorLink>
                  </NavItem>
                </>
              )}
              {isMobile && (
                <>
                  <NavItem>
                    <AnchorLink
                      className="nav-link"
                      offset="80"
                      href="#creations"
                    >
                      Projects
                    </AnchorLink>
                  </NavItem>
                  <NavItem>
                    <AnchorLink
                      className="nav-link"
                      offset="80"
                      href="#testimonials"
                    >
                      Agency
                    </AnchorLink>
                  </NavItem>
                </>
              )}
              <NavItem>
                <AnchorLink
                  className="nav-link nav-link--item"
                  offset="80"
                  href="#contact"
                >
                  Contact
                </AnchorLink>
              </NavItem>
              {!isMobile && (
                <NavItem>
                  <Button className="nav-link" color="link" onClick={toggleWeb}>
                    <div className="img-fluid burger-icon" />
                  </Button>
                </NavItem>
              )}
            </Nav>
          </Collapse>
          <div
            className={classNames({
              "collapse-container-active": isOpenWeb,
            })}
          >
            <div className="collapse-web">
              <Collapse isOpen={isOpenWeb}>
                <NavbarToggler
                  className="main-navbar--toggler-active"
                  onClick={toggleWeb}
                />
                <Nav className="main-navbar--nav mt-4 mt-md-0" navbar>
                  <NavItem>
                    <AnchorLink className="nav-link" offset="80" href="#home">
                      Home
                    </AnchorLink>
                  </NavItem>
                  <NavItem>
                    <AnchorLink
                      className="nav-link"
                      offset="80"
                      href="#creations"
                    >
                      Projects
                    </AnchorLink>
                  </NavItem>
                  <NavItem>
                    <AnchorLink
                      className="nav-link"
                      offset="80"
                      href="#testimonials"
                    >
                      Agency
                    </AnchorLink>
                  </NavItem>
                  <NavItem>
                    <AnchorLink
                      className="nav-link"
                      offset="80"
                      href="#contact"
                    >
                      Contact
                    </AnchorLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </div>
          </div>
        </Navbar>
      </Container>
    </header>
  )
}

export default NavbarPremier

export const HeaderSection = graphql`
  fragment HeaderSection on SanitySectionHeader {
    link
    enable
    logo {
      alt
      image {
        ...ImageWithPreview
      }
    }
  }
`
