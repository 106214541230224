import React from "react"
import Navbar from "../navbar"
import Footer from "../footer"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

const Layout = ({ children, social }) => {
  return (
    <>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_GOGGLE_RECAPTCHA_SITE_KEY}
        language="es"
        scriptProps={{
          async: true,
          defer: true,
        }}
      >
        <Navbar />
        <main>{children}</main>
        <Footer social={social} />
      </GoogleReCaptchaProvider>
    </>
  )
}

export default Layout
