import React from "react"
import { useMediaQueries } from "@react-hook/media-query"
import { Container } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Footer = ({ social }) => {
  const {
    matches: { isMobile },
  } = useMediaQueries({
    isMobile: "only screen and (max-width: 992px)",
  })

  const RenderSocial = () => {
    return social?.map(item => (
      <a href={item.link} target={item.target} key={item._key}>
        {item.text === "instagram" && (
          <FontAwesomeIcon
            icon={["fab", "instagram"]}
            className="social-icon"
          />
        )}
        {item.text === "facebook" && (
          <FontAwesomeIcon icon={["fab", "facebook"]} className="social-icon" />
        )}
        {item.text === "twitter" && (
          <FontAwesomeIcon icon={["fab", "twitter"]} className="social-icon" />
        )}
        {item.text === "linkedin" && (
          <FontAwesomeIcon
            icon={["fab", "linkedin-in"]}
            className="social-icon"
          />
        )}
        {item.text === "github" && (
          <FontAwesomeIcon icon={["fab", "github"]} className="social-icon" />
        )}
      </a>
    ))
  }

  return (
    <footer className="main-footer">
      <Container className="d-flex flex-column align-items-center justify-content-center">
        {!isMobile && (
          <div className="tabs">
            <AnchorLink offset={80} href="#our-services">
              Our Services
            </AnchorLink>
            <AnchorLink offset={80} href="#creations">
              Creations
            </AnchorLink>
            <AnchorLink offset={80} href="#about">
              About
            </AnchorLink>
            <AnchorLink offset={80} href="#contact">
              Contact
            </AnchorLink>
          </div>
        )}
        {isMobile && (
          <div className="tabs social">
            <RenderSocial />
          </div>
        )}
        <div className="logo-container">
          <img
            src="logo-footer.svg"
            alt="RV Dev"
            className="img-fluid mx-auto"
          />
          <p className="copyright">Red valley 2021. All rights reserved</p>
        </div>
      </Container>
    </footer>
  )
}
export default Footer
